<template>
  <div class="page-wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader">
      <div class="icon"></div>
    </div> -->

    <!-- Main Header -->
    <header class="main-header header-style-two">
      <div class="header-container">

        <!-- Header Top -->
        <div class="header-top">
          <div class="auto-container">
            <div class="inner clearfix">
              <div class="top-left">
                <div class="top-text">Welcome to Dragon City</div>
              </div>

              <div class="top-right">
                <ul class="info clearfix">
                  <li><a href="https://www.youtube.com/GameXO?sub_confirmation=1" target="_blank">YouTube</a></li>
                  <li><a href="mailto:guide@dragoncityz.com">guide@dragoncityz.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Header Upper -->
        <div class="header-upper">
          <div class="auto-container">
            <div class="inner-container clearfix">
              <!--Logo-->
              <div class="logo-box">
                <div class="logo">
                  <router-link to="/"><img src="/images/logo.png" alt="DragonCityZ" title="DragonCityZ">
                  </router-link>
                </div>
              </div>

              <!--Nav Box-->
              <div class="nav-outer clearfix">
                <!--Mobile Navigation Toggler-->
                <div class="mobile-nav-toggler"><span class="icon flaticon-menu-2"></span></div>

                <!-- Main Menu -->
                <nav class="main-menu navbar-expand-md navbar-light">
                  <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                    <ul class="navigation clearfix">
                      <li :class="
                      ['/dragons'].includes(this.$route.path)
                        ? `current`
                        : '' ">
                        <router-link to="/dragons">Dragons</router-link>
                      </li>
                      <li class="dropdown" :class="
                      ['/ranked', '/top'].includes(this.$route.path)
                        ? `current`
                        : '' ">
                        <a href="javascript:void(0)">Leaderboards</a>
                        <ul>
                          <li>
                            <router-link to="/ranked">Dragons Ranked</router-link>
                          </li>
                          <li>
                            <router-link to="/top-strongest-dragons">TOP strongest dragons</router-link>
                          </li>
                        </ul>
                      </li>
                      <li class="dropdown"><a href="javascript:void(0)">Calculator</a>
                        <ul>
                          <li>
                            <router-link to="/dragon-comparison">Dragon Comparison</router-link>
                          </li>
                          <!-- <li>
                            <router-link to="/power-and-hp-calculator">Power and HP calculator</router-link>
                          </li>
                          <li>
                            <router-link to="/how-to-breed-any-dragon">How to breed any Dragon</router-link>
                          </li>
                          <li>
                            <router-link to="/breeding-calculator">Breeding calculator</router-link>
                          </li>
                          <li>
                            <router-link to="/food-calculator">Food calculator</router-link>
                          </li>
                          <li>
                            <router-link to="/strong-and-weakness-chart">Strong and weakness chart</router-link>
                          </li> -->
                        </ul>
                      </li>                      
                      <li :class="
                      ['/about'].includes(this.$route.path)
                        ? `current`
                        : '' ">
                        <router-link to="/about">About</router-link>
                      </li>
                    </ul>
                  </div>
                </nav>
                <!-- Main Menu End-->

                <ul class="social-links clearfix">
                  <li><a href="https://dragoncityz.com"><span class="fab fa-twitter"></span></a></li>
                  <li><a href="https://dragoncityz.com"><span class="fab fa-facebook-square"></span></a></li>
                  <li><a href="https://dragoncityz.com"><span class="fab fa-linkedin-in"></span></a></li>
                  <li><a href="https://dragoncityz.com"><span class="fab fa-pinterest-p"></span></a></li>
                </ul>

              </div>
            </div>
          </div>
        </div>
        <!--End Header Upper-->
      </div>
      <!--End Header Container-->

      <!-- Sticky Header  -->
      <div class="sticky-header">
        <div class="auto-container clearfix">
          <!--Logo-->
          <div class="logo pull-left">
            <router-link to="/"><img src="/images/sticky-logo.png" alt="DragonCityZ" title="DragonCityZ"></router-link>
          </div>
          <!--Right Col-->
          <div class="pull-right">
            <!-- Main Menu -->
            <nav class="main-menu clearfix">
              <!--Keep This Empty / Menu will come through Javascript-->
            </nav><!-- Main Menu End-->
          </div>
        </div>
      </div><!-- End Sticky Menu -->

      <!-- Mobile Menu  -->
      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><span class="icon flaticon-cancel"></span></div>

        <nav class="menu-box">
          <div class="nav-logo">
            <router-link to="/">
              <img src="/images/logo.png" alt="DragonCityZ" title="DragonCityZ">
            </router-link>
          </div>
          <div class="menu-outer">
            <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
          </div>
          <!--Social Links-->
          <div class="social-links">
            <ul class="clearfix">
              <li><a href="https://dragoncityz.com"><span class="fab fa-twitter"></span></a></li>
              <li><a href="https://dragoncityz.com"><span class="fab fa-facebook-square"></span></a></li>
              <li><a href="https://dragoncityz.com"><span class="fab fa-pinterest-p"></span></a></li>
              <li><a href="https://dragoncityz.com"><span class="fab fa-instagram"></span></a></li>
              <li><a href="https://dragoncityz.com"><span class="fab fa-youtube"></span></a></li>
            </ul>
          </div>
        </nav>
      </div><!-- End Mobile Menu -->
    </header>
    <!-- End Main Header -->
    
    <router-view :dragons=dragons :elements=elements :skills=skills />

    <!-- Main Footer -->
    <footer class="main-footer">
      <div class="top-pattern-layer-dark"></div>

      <div class="auto-container">
        <!--Widgets Section-->
        <div class="widgets-section">
          <div class="row clearfix">

            <!--Column-->
            <div class="column col-lg-4 col-md-6 col-sm-12">
              <div class="footer-widget logo-widget">
                <div class="widget-title">
                  <h3>About us</h3>
                </div>
                <div class="text">
                  I'm writing this guide with the hope that it helps you avoid the similar frustration I experienced learning this game. It also serves as my thank you to this sub, whose content and input finally tied it all together for me.
                  <br>
                  While I call this a 'Beginners' guide, the truth is I am including many of the tips and tricks I use now as a level 98 Masters 3 player. I will do my best to speak to returning players as I can, but I didn't live that dynamic so I'm uncertain of all the unique challenges you may be facing.
                </div>
              </div>
            </div>

            <!--Column-->
            <div class="column col-lg-5 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <div class="widget-content">
                  <div class="row clearfix">
                    <div class="column col-lg-6 col-md-6 col-sm-12">
                      <div class="widget-title">
                        <h3>Explore</h3>
                      </div>
                      <ul class="list">
                        <li><a href="/dragons">Dragons</a></li>
                        <li><a href="#">Events</a></li>
                        <li><a href="/top-strongest-dragons">Top 10 Strongest Dragons</a></li>
                        <li><a href="/calculator">Calculator</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                      </ul>
                    </div>
                    <div class="column col-lg-6 col-md-6 col-sm-12">
                      <div class="widget-title">
                        <h3>Our Websites</h3>
                      </div>
                      <ul class="list">
                        <li><a href="https://dragoncityz.com">Dragon CityZ</a></li>
                        <li><a href="https://dragonmania.net" target="_blank">Dragon Mania Legends</a></li>
                        <li><a href="https://www.youtube.com/GameXO?sub_confirmation=1" target="_blank">YouTube</a></li>
                        <li><a href="https://www.facebook.com/DML.Club/" target="_blank">Facebook</a></li>
                        <li><a href="https://discord.gg/s9BUfReYBB" target="_blank">Discord</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Column-->
            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget info-widget">
                <ul class="contact-info">
                  <li><strong>Phone</strong> <br><a href="tel:666-888-000">666 888 000</a></li>
                  <li><strong>Address</strong> <br>88 road, broklyn street <br>new york, usa</li>
                  <li><strong>Email Address</strong> <br><a
                      href="mailto:admin@dragoncityz.com">admin@dragoncityz.com</a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Footer Bottom -->
      <div class="footer-bottom">
        <div class="outer-container">
          <!--Footer Bottom Shape-->
          <div class="bottom-shape-box">
            <div class="bg-shape"></div>
          </div>

          <div class="auto-container">
            <!--Scroll to top-->
            <div class="scroll-to-top scroll-to-target" data-target="html"><span class="flaticon-up-arrow"></span></div>
            <!--Scroll to top-->
            <div class="row clearfix">
              <!-- Column -->
              <div class="column col-lg-6 col-md-12 col-sm-12">
                <div class="copyright"><span class="logo-icon"></span> &copy; Copyrights 2021 <a
                    href="https://dragoncityz.com">DragonCityZ</a> - All Rights Reserved</div>
              </div>
              <!-- Column -->
              <div class="column col-lg-6 col-md-12 col-sm-12">
                <div class="social-links">
                  <ul class="default-social-links">
                    <li><a href="https://dragoncityz.com"><span class="fab fa-twitter"></span></a></li>
                    <li><a href="https://dragoncityz.com"><span class="fab fa-facebook-square"></span></a></li>
                    <li><a href="https://dragoncityz.com"><span class="fab fa-pinterest-p"></span></a></li>
                    <li><a href="https://dragoncityz.com"><span class="fab fa-instagram"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>

  <div class="scroll-to-top scroll-to-target" data-target="html"><span class="flaticon-up-arrow"></span></div>
</template>

<script>
  import dragons from './data/dragons'
  import elements from './data/elements'
  import skills from './data/skills'

  export default {
    name: 'App',
    components: {
    },
    data() {
      return {
        dragons: [],
        elements: [],
        skills: [],
      }
    },
    mounted() {
      this.dragons = dragons
      this.elements = elements
      this.skills = skills
    }
  }
</script>
<style>

</style>