import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Dragons" */ '../views/Dragons.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/dragons/:dragon',
    name: 'Dragon',
    component: () => import(/* webpackChunkName: "Dragons" */ '../views/Dragon.vue')
  },
  {
    path: '/dragons',
    name: 'Dragons',
    component: () => import(/* webpackChunkName: "Dragons" */ '../views/Dragons.vue')
  },
  {
    path: '/ranked',
    name: 'Ranked',
    component: () => import(/* webpackChunkName: "Ranked" */ '../views/Ranked.vue')
  },
  {
    path: '/top-strongest-dragons',
    name: 'Top',
    component: () => import(/* webpackChunkName: "Top" */ '../views/Top.vue')
  },
  {
    path: '/dragon-comparison',
    name: 'Comparison',
    component: () => import(/* webpackChunkName: "Top" */ '../views/Comparison.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
